import React, { createContext, useEffect, useState } from "react";
import { splitAPIKey } from "./constants";
import { SplitFactory } from "@splitsoftware/splitio";
import SplitIO, { IBrowserSDK } from "@splitsoftware/splitio/types/splitio";
import { useCookies } from "react-cookie";

// All splits for marketing frontend should go here
export const splitTestId = "MKWB-323-Hero-Bifurcated";

const anonymousTrafficType = "anonymous_user";

interface SplitClient {
  client: SplitIO.IBrowserClient | null;
  isReady: boolean;
}
interface ISplitClientContext {
  anonymousClient: SplitClient;
}
const initialState: ISplitClientContext = {
  anonymousClient: {
    client: null,
    isReady: false,
  },
};

const getSplitFactory = (key: string) =>
  SplitFactory({
    core: {
      authorizationKey: splitAPIKey,
      key: key,
      /** Initialize factory with
       * anonymous user traffic type.
       * This can be changed with a new
       * client call passing a different
       * traffic type. See docs
       * https://bit.ly/3expSDK
       */
      trafficType: anonymousTrafficType,
    },
    startup: {
      readyTimeout: 1.5,
    },
    scheduler: {
      impressionsRefreshRate: 1,
    },
  });

export const SplitClientContext =
  createContext<ISplitClientContext>(initialState);

const XomSplitFactory = ({ children }: any) => {
  const [splitState, setSplitState] = useState(initialState);
  const anonIDCookieName = "ajs_anonymous_id";
  const [cookies] = useCookies([anonIDCookieName]);

  // Setup split factory with anonymous ID on first render
  useEffect(() => {
    let anonID = cookies.ajs_anonymous_id as string | undefined;
    if (anonID && splitState.anonymousClient.isReady === false) {
      const factory = getSplitFactory(anonID);
      const anonymousClient = factory?.client();
      anonymousClient.on(anonymousClient.Event.SDK_READY, () => {
        setSplitState({
          anonymousClient: { client: anonymousClient, isReady: true },
        });
      });
    }
  }, [cookies]);

  return (
    <SplitClientContext.Provider value={splitState}>
      {children}
    </SplitClientContext.Provider>
  );
};

export default XomSplitFactory;
